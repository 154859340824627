<template>
  <div
    ref="el"
    class="relative flex flex-col items-start w-full h-[450dvh] lg:flex-row bg-[#0047A5]"
  >
    <div class="w-full lg:basis-full" :class="spacing">
      <p
        class="title-text text-center text-[#ECEBD2] xl:leading-[8rem] font-Kontesa font-bold text-shadow"
      >
        We believe
      </p>
      <div class="flex justify-center circle-mt">
        <div class="w-fit p-4 rounded-full bg-[#ECEBD2] bg-opacity-10">
          <div class="p-4 rounded-full bg-[#ECEBD2] bg-opacity-10">
            <div class="p-4 rounded-full bg-[#ECEBD2] bg-opacity-10">
              <div class="p-4 rounded-full bg-[#ECEBD2] bg-opacity-10">
                <div
                  class="flex justify-center items-center circle-size rounded-full bg-[#ECEBD2] bg-opacity-100"
                >
                  <transition name="fade" mode="out-in">
                    <p
                      :key="activeKey"
                      class="text-center max-w-[147px] xl:max-w-[248px] text-lg"
                    >
                      {{ items[activeKey] }}
                    </p>
                  </transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        :style="{ marginTop: position }"
        class="flex justify-center transition-all duration-700 text-[#0A56BB] text-shadow shadow-text number-text number-leading font-Kontesa font-bold"
      >
        <div
          v-for="(_, key) of items"
          :key="key"
          class="flex gap-4 transition-all duration-700 px-4 lg:px-16"
          :class="
            `${activeKey}` === `${key}`
              ? 'text-[#ECEBD2] -translate-y-10  xl:-translate-y-16'
              : key + ' ' + typeof key
          "
        >
          <div :class="[{ hidden: progress < 0.1 }]">0{{ key }}</div>
        </div>
      </div>

      <div class="w-full h-2 bg-[#0A56BB] overflow-hidden">
        <div
          class="h-full bg-[#ECEBD2] transition-all duration-300"
          :style="{ width: `${((1 - progress) / (2 / 3)) * 100}%` }"
        ></div>
      </div>

      <div
        class="relative flex flex-col items-center w-full -mt-1 pb-24 bg-[#0047A5]"
      >
        <!-- <div
          class="absolute w-full h-full opacity-[0.17] bg-[url(/images/home/bg-texture.png)] z-0"
        ></div> -->
        <p
          class="ellipsis block overflow-hidden h-24 xl:h-auto w-[341px] xl:w-[872px] mt-[104px] xl:mt-[156px] text-3xl xl:text-[64px] text-center text-[#ECEBD1] font-Kontesa font-bold leading-8 xl:leading-[4rem] z-10"
        >
          We’re changing impact from me to we, by unleaching the power of the
          crowd
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const el = ref<HTMLDivElement | null>(null);
const { progress } = useScrollProgress(el);

const spacing = computed(() => {
  return progress.value < 1 ? "sticky top-[1vh]" : "mt-[10vh] lg:mt-[18vh]";
});

const stepSize = ref(15);
const position = computed(() => {
  return `${stepSize.value / 2}vh`;
});

const activeKey = ref<1 | 2 | 3>(1);
watch(progress, (value) => {
  if (value < 0.6) {
    return (activeKey.value = 3);
  }
  if (value < 0.8) {
    return (activeKey.value = 2);
  }
  return (activeKey.value = 1);
});

const items: { [key in 1 | 2 | 3]: string } = {
  "1": "Together we can make a real difference.",
  "2": "Instead of begging billionaires for funds, we can create our own billionaire.",
  "3": "Community is built on shared values and impact.",
};
</script>

<style scoped>
.title-text {
  @apply md:text-8xl min-[1024px]:text-8xl min-[1400px]:text-9xl text-[4rem] xl:text-8xl 2xl:text-9xl;
}

.circle-size {
  @apply w-[12.8rem] h-[12.8rem] md:w-[20rem] md:h-[20rem] lg:w-[12.8rem] lg:h-[12.8rem] min-[1280px]:w-[11.5rem] min-[1280px]:h-[11.5rem] min-[1366px]:w-48 min-[1366px]:h-48 min-[1400px]:w-64 min-[1400px]:h-64 min-[1440px]:w-56 min-[1440px]:h-56 min-[1600px]:w-56 min-[1600px]:h-56 min-[1680px]:w-64 min-[1680px]:h-64 min-[1920px]:w-[15.2rem] min-[1920px]:h-[15.2rem];
}

.circle-mt {
  @apply mt-10 min-[1024px]:mt-10 min-[1280px]:mt-8 min-[1366px]:mt-14 min-[1400px]:mt-32 min-[1440px]:mt-32 min-[1600px]:mt-24 min-[1680px]:mt-[8rem] min-[1920px]:mt-[3rem];
}

.number-text {
  @apply text-[7rem] md:text-[14rem] min-[1024px]:text-[12rem] min-[1280px]:text-[14rem] min-[1366px]:text-[16rem] min-[1400px]:text-[20rem] min-[1440px]:text-[16rem] min-[1600px]:text-[16rem] min-[1680px]:text-[20rem] min-[1920px]:text-[20rem];
}

.number-leading {
  @apply leading-[10rem] lg:leading-[13rem] min-[1400px]:leading-[19rem] min-[1440px]:leading-[15rem] min-[1600px]:leading-[15rem] min-[1680px]:leading-[19rem] min-[1920px]:leading-[18rem];
}

.shadow-text {
  text-shadow: 0.008em 10px black, 0 1px black;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
