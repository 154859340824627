import { useElementBounding, useWindowSize } from "@vueuse/core";

export default function (target: globalThis.Ref) {
  const { bottom, height } = useElementBounding(target);
  const { height: windowH } = useWindowSize();

  const progress = computed(() => {
    const progressValue = bottom.value / windowH.value;
    const ratio = height.value / windowH.value;
    const absoluteValue = progressValue / ratio;

    if (absoluteValue < 0) return 0;
    if (absoluteValue > 1) return 1;

    return absoluteValue;
  });

  const animate = (p: number, a: number[], b: number[]) => {
    let finalValue = 1 - p;

    if (a.length % 2 !== 0 || b.length % 2 !== 0) return finalValue;
    if (finalValue > a[a.length - 1]) return b[b.length - 1];

    for (let i = 0; i < a.length - 1; i += 2) {
      if (1 - p < a[i]) {
        finalValue = b[i];
        break;
      } else if (1 - p < a[i + 1]) {
        const m = (b[i + 1] - b[i]) / (a[i + 1] - a[i]);
        finalValue = m * (1 - p - a[i]) + b[i];
        break;
      } else {
        finalValue = b[i + 1];
      }
    }

    return finalValue;
  };

  return { progress, animate };
}
